import React from 'react'

// Components
import ServicesBlock from 'components/services-block'

function URServices() {
  return (
    <ServicesBlock
      title={
        <span>
          <span className="has-text-purple">Make evidence-based </span> digital
          product decisions
        </span>
      }
      subtitle="We help you conduct robust user research to gain insight into your customers and inform your digital product strategy."
      items={[
        {
          title: 'Participant Recruitment',
          text: 'Find the right people to learn from and inform the direction of your mobile app or digital product.',
        },
        {
          title: 'Interview Scripts',
          text: 'Clearly undersatnd what what you need to learn and define unbiased scripts for research interviews.',
        },
        {
          title: 'Customer Interviews',
          text: 'Learn what your customers think and where they struggle using face to face user interviews.',
        },
        {
          title: 'Focus Groups',
          text: 'Better inform your product strategy by learning how groups think and why they think that way.',
        },
        {
          title: 'Usability Tests',
          text: 'Ensure your product will be well received by conducting user tests on your target audience.',
        },
        {
          title: 'Research Repositories',
          text: 'Pull your research data together in one place and make it accessible to people across your organisation.',
        },
        {
          title: 'Surveys & Questionnaires',
          text: 'Collect quantitative data to support your mobile strategy using surveys and questionnaires.',
        },
        {
          title: 'Mobile Analytics',
          text: 'Define conversion funnels for your app and learn where improvements can be made.',
        },
        {
          title: 'Mobile Usability',
          text: 'Improve your app experience by finding issues around standards, accessibility and usability.',
        },
      ]}
    />
  )
}

export default URServices
