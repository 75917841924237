import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import Testimonials from 'components/testimonials'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objectives from 'components/objectives'
import AnnaQuote from 'components/annaQuote'

// Page Components
import URHero from './components/ur-hero'
import Stats from './components/stats'
import URServices from './components/ur-services'
import URThoughts from './components/ur-thoughts'

function ServicesUserResearch({ data: { page, testimonials, posts } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <URHero page={page} />
      <Stats />
      <URServices />
      <Testimonials testimonials={testimonials} />
      <URThoughts posts={posts} />
      <Clients />
      <Customers />
      <Objectives />
      <AnnaQuote />
    </Layout>
  )
}

ServicesUserResearch.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesUserResearch
