import PropTypes from 'prop-types'
import React from 'react'
import { Container, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function URHero({ page }) {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <div className="columns is-tablet is-vcentered">
            <div className="column is-6 is-offset-1">
              <h1 className="title">
                User <span className="has-text-primary">Research</span>
              </h1>
              <p className="is-size-4">
                Find new opportunities to solve customer problems and design
                solutions that resonate.
              </p>
            </div>
            <div className="column is-4">
              <GatsbyImage
                image={page.featuredMedia.gatsbyImageData}
                alt="User Research - Pocketworks"
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
URHero.propTypes = {
  page: PropTypes.object,
}
export default URHero
